import './plugins/inView';

import {
    TweenMax,
    Expo
} from 'gsap/all';
import Splitting from 'splitting';
import VanillaTilt from 'vanilla-tilt';
import dataStore from './inc/dataStore';
import browserDetection from './inc/browserDetection';

// import smoothScroll from './inc/smoothScroll';

const jQuery = require('jquery');
const LazyLoad = require('vanilla-lazyload');

window.jQuery = jQuery;
window.$ = jQuery;

/* Author: herrlich media */
$(document).ready(() => {
    // Ready Starts

    // Trigger Scroll
    $(window).scrollTop($(window).scrollTop() + 1);
    $(window).scrollTop($(window).scrollTop() - 1);

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Ready Inits +++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* Set Datastore Jquery Vars ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.bigheaderContent = $('.bigheaderContent .headline');
    if (dataStore.bigheaderContent.length) {
        dataStore.bigheaderContentHeight = dataStore.bigheaderContent.height()
        + dataStore.bigheaderContent.offset().top;
    }
    /* browser/touch detection ++++++++++++++++++++++++++++++++++++++++++*/
    browserDetection();

    // countdown function
    countdown();

    // Smooth Mousewheel Scrolling for Chrome & Safari
    // smoothScroll();

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Plugins +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
    dataStore.lazyLoadInstance = new LazyLoad({
        elements_selector: '.lazy'
    });
    lazyloadUpdate();

    /* Splitting JS  ++++++++++++++++++++++++++++++++++++++++++*/
    Splitting();

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Jingles +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    const jingleContainer = $('#jingleContainer');
    let jinglesHtml = '';

    const jingleJson = JSON.parse(jingleData());
    if (jingleContainer.length) {
        for (let jingleIndex = 1; jingleIndex < jingleJson.length + 1; jingleIndex++) {
            const currentJingle = jingleJson[jingleIndex - 1];
            let jingleNumber = jingleIndex;
            if (jingleNumber < 10) {
                jingleNumber = `00${jingleNumber}`;
            } else if (jingleNumber < 100) {
                jingleNumber = `0${jingleNumber}`;
            }

            // Get Jingle HTML
            jinglesHtml += '<div class="col-6 col-xs-6 col-sm-6 col-md-3 col-lg-2 mb-1 mb-md-2">';

            jinglesHtml += `<div class="jingle" id="${currentJingle.link.split(':')[2]}">`;

            jinglesHtml += '<div class="jingleImg">';
            jinglesHtml += `<a target="_blank" href="${currentJingle.link}">`;
            jinglesHtml += `<span class="h4">#${jingleNumber}</span>`;
            jinglesHtml += `<img class="lazy" data-src="assets/thumbnails/jingleBe-${jingleIndex}-min.jpg" alt="Jingle
#${jingleNumber}"src="assets/placeholder.jpg" />`;
            jinglesHtml += '</a>';
            jinglesHtml += '</div>';

            if (jingleIndex !== 30) {
                jinglesHtml += `<audio class="player" controls><source src="assets/jingles/jingleBe-
${jingleIndex}.mp3" type="audio/mpeg"></audio>`;
            }

            jinglesHtml += '<div class="jinglePlayer">';

            if (jingleIndex !== 30) {
                jinglesHtml += '<div class="playBtn"><div class="pause"></div></div>';
            }

            jinglesHtml += '<div class="jingleContent">';
            jinglesHtml += `<a target="_blank" href="${currentJingle.link}">`;
            jinglesHtml += `<span class="h4">${currentJingle.name}</span>`;
            jinglesHtml += '</a>';
            jinglesHtml += '<span class="time"></span>';
            jinglesHtml += '</div>';

            jinglesHtml += '</div>';

            jinglesHtml += '</div>';

            jinglesHtml += '</div>';
        }
        jingleContainer.append(jinglesHtml);

        const players = document.getElementsByClassName('player');
        for (let playerIndex = 0; playerIndex < players.length; playerIndex++) {
            const player = players[playerIndex];

            // Get Audio Time
            player.onloadedmetadata = (e) => {
                const thePlayer = e.currentTarget;
                const playerDuration = thePlayer.duration;
                const minutes = Math.floor((playerDuration % (60 * 60)) / (60));
                const seconds = Math.floor(playerDuration % (60));
                const finalTime = `${str_pad_left(minutes, '0', 1)}:${str_pad_left(seconds, '0', 2)}`;
                $(thePlayer).siblings('.jinglePlayer').find('.time').text(finalTime);
            };

            // Tilt Effekt
            const tiltEl = players[playerIndex].parentNode;
            VanillaTilt.init(tiltEl);
        }

        // Jingle Z-Index
        let jingleIndex = 1;
        $('.jingle').on('mouseenter touch', (e) => {
            jingleIndex++;
            if (jingleIndex > 99) {
                jingleIndex = 1;
                $('.jingle').css({
                    'z-index': 1
                });
            }
            $(e.currentTarget).css({
                'z-index': jingleIndex
            });
        });

        /* lazy load  ++++++++++++++++++++++++++++++++++++++++++*/
        lazyloadUpdate();

        // IN View
        $('.jingle').one('inview', (e, visible) => {
            if (visible === true) {
                //$(e.currentTarget).find('source').attr('src', $(e.currentTarget).find('source').attr('data-src'));
                // $(e.currentTarget).find('.player').get(0).load();
                $(e.currentTarget).addClass('ready');
//                 const jingleId = $(e.currentTarget).attr('id');
//                 console.log(jingleId);
//                 if (jingleId !== undefined && jingleId !== 'undefined') {
//                     $.ajax({
//                         url: `https://api-mainnet.rarible.com/items/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:
// ${jingleId}`,
//                         cache: false,
//                         async: false
//                     }).done((response) => {
//                         console.log(response);
//                         // console.log(response.item.offer.canceled);
//                         // console.log(response.item.offer.buyPriceEth);
//                     });
//                 }
            }
        });

        // Play Btn CLick/Touch
        $(document).on('click touch', '.playBtn', (e) => {
            const currentPlayer = $(e.currentTarget).parent().siblings('.player');
            const otherPlayers = $('.player').not(currentPlayer);
            for (let otherPlayersIndex = 0; otherPlayersIndex < otherPlayers.length; otherPlayersIndex++) {
                $(otherPlayers[otherPlayersIndex]).removeClass('playing').get(0).pause();
                $(otherPlayers[otherPlayersIndex]).parent().removeClass('playing');
            }

            if (currentPlayer.hasClass('playing')) {
                currentPlayer.removeClass('playing').get(0).pause();
                currentPlayer.parent().removeClass('playing');
            } else {
                currentPlayer.addClass('playing').get(0).play();
                currentPlayer.parent().addClass('playing');
            }
        });
    }

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Eventlistener +++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    // Jingle Toggle
    // $(document).on('mouseenter', '.jingle', (e) => {
    //     const player = $(e.currentTarget).find('.jinglePlayer')[0];
    //     player.play();
    // }).on('mouseleave', '.jingle', (e) => {
    //     const player = $(e.currentTarget).find('.jinglePlayer')[0];
    //     player.pause();
    // });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Sonstiges +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    /*--- Scroll to ID on Click ---*/
    $('a[href*="#"]:not([href="#"])').on('click touch', (e) => {
        if (window.location.pathname.replace(/^\//, '') === e.currentTarget.pathname.replace(/^\//, '')
                && window.location.hostname === e.currentTarget.hostname) {
            let hashTarget = $(e.currentTarget.hash);
            hashTarget = hashTarget.length ? hashTarget : $(`[name=${e.currentTarget.hash.slice(1)}]`);
            if (hashTarget.length) {
                $('.open').removeClass('open');
                $('html,body').animate({
                    scrollTop: hashTarget.offset().top - 150
                }, 300);
                e.preventDefault();
            }
        }
    });

    /*++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* Scroll ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
    /* +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

    $(window).scroll((e) => {
        if (Object.prototype.hasOwnProperty.call(e, 'target')
            && Object.prototype.hasOwnProperty.call(e.target, 'scrollingElement')
            && Object.prototype.hasOwnProperty.call(e.target.scrollingElement, 'scrollTop')
        ) {
            dataStore.docScroll = e.target.scrollingElement.scrollTop;
        } else {
            dataStore.docScroll = $(window).scrollTop();
        }

        if (dataStore.docScroll > dataStore.bigheaderContentHeight) {
            initInView();
        }
    });
}); /* ready end ++++++++++++++++++++++++++++++++++++++++++*/

$(window).resize(() => {
    dataStore.docWidth = $(document).width();
    dataStore.docHeight = $(document).height();
    dataStore.winHeight = $(window).height();

    $(window).trigger('scroll');

    clearTimeout(dataStore.endOfResize);
    dataStore.endOfResize = setTimeout(() => {
        afterResize();
    }, 250);
});

function afterResize() {
}

$(window).on('load', () => {
    // LOAD START

    // Big Header on Load
    $('#bigHeader').addClass('loaded');
    setTimeout(() => {
        const chars = $('h1 .char, h1 .whitespace');
        for (let charsIndex = 0; charsIndex < chars.length; charsIndex++) {
            setTimeout(() => {
                $(chars[charsIndex]).addClass('vis');

                if (charsIndex === chars.length - 1) {
                    initInView();
                }
            }, 30 * charsIndex);
        }
    }, 500);

    // Mouse Parallax
    const mouseParallax = $('.mouseParallax');

    for (let mouseParallaxIndex = 0; mouseParallaxIndex < mouseParallax.length; mouseParallaxIndex++) {
        const thisMouseParallax = $(mouseParallax[mouseParallaxIndex]);

        let mousePositiv = '-';
        let mouseX = 0;
        let mouseY = 0;

        if (thisMouseParallax.hasClass('mousePositiv')) {
            mousePositiv = '';
        }

        $(document).on('mousemove', (event) => {
            mouseX = `${mousePositiv}${(event.pageX - (dataStore.docWidth / 2)) / 20}`;
            mouseY = `${mousePositiv}${(event.pageY - dataStore.docScroll - (dataStore.winHeight / 2)) / 20}`;
            TweenMax.to(thisMouseParallax, 1.1, {
                x: mouseX,
                y: mouseY,
                ease: Expo.easeOut,
                overwrite: 5
            });
        });
    }

    // Jingle Counter
    const jingleCount = $('.jingleCount');
    jingleCount.one('inview', (e, visible) => {
        if (visible === true) {
            setTimeout(() => {
                $({ countNum: 0 }).animate({ countNum: 100 }, {
                    duration: 4000,
                    easing: 'swing',
                    step: (countNum) => {
                        let outputNumber = countNum;

                        if (countNum < 10) {
                            outputNumber = `00${Math.floor(countNum)}`;
                        } else if (countNum < 100) {
                            outputNumber = `0${Math.floor(countNum)}`;
                        }

                        jingleCount.html(`#${outputNumber}`);
                    }
                });
            }, 450);
        }
    });
});

// Lazyload Update
const lazyloadUpdate = function lazyloadUpdate() {
    if (dataStore.lazyLoadInstance !== '') {
        dataStore.lazyLoadInstance.update();
    }
};

// Init inview
function initInView() {
    if (dataStore.inviewInited === false) {
        dataStore.inviewInited = true;

        /* In View  ++++++++++++++++++++++++++++++++++++++++++*/
        $('.fadeIn').one('inview', (e, visible) => {
            if (visible === true) {
                $(e.currentTarget).not('.vis').addClass('vis');
            }
        });

        $('.fadeInRow').one('inview', (e, visible) => {
            if (visible === true) {
                $(e.currentTarget).find('> div, > li, > p, > span, > a, > h3').not('.vis').each((i, el) => {
                    setTimeout(() => {
                        $(el).addClass('vis');
                    }, 150 * i);
                });
            }
        });
    }
}

function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
}

function countdown() {
    const $countdown = $('.countdown');
    const countdownContainer = $('.countdown, .countdownContainer');
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let milliseconds = 0;

    if ($countdown.length) {
        const countDownDate = new Date('Mar 7, 2021 20:00:00').getTime();
        let now = new Date().getTime();
        let distance = countDownDate - now;

        if (distance > 0) {
            countdownContainer.show();
            const x = setInterval(() => {
                now = new Date().getTime();
                distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                days = Math.floor(distance / (1000 * 60 * 60 * 24));
                hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                seconds = Math.floor((distance % (1000 * 60)) / 1000);
                milliseconds = Math.floor(distance % 1000);

                let displayCountdown = `${days}d ${hours}h ${minutes}m ${seconds}s
                <span class="ms">${milliseconds}ms</span>`;

                if (hours === 0 && days === 0) {
                    displayCountdown = `${minutes}m ${seconds}s <span class="ms">${milliseconds}ms</span>`;
                } else if (days === 0) {
                    displayCountdown = `${hours}h ${minutes}m ${seconds}s <span class="ms">${milliseconds}ms</span>`;
                }
                $countdown.html(displayCountdown);

                // If the count down is finished, write some text
                if (distance < 0) {
                    clearInterval(x);
                    countdownContainer.hide();
                }
            }, 60);
        } else {
            countdownContainer.hide();
        }
    }
}

// get Jingle Data
/* eslint-disable */
function jingleData() {
    return `[
            {
                "name": "always hungry",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:2:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "sundance",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:3:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "suprise MF",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:4:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "to the moon",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:5:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "lonely statement",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:6:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "The Final Frontier",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:7:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "me delighted",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:9:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "lost",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:10:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "miserable",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:11:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "bitter better",
                "link": "https://app.rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:218765:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "i am worried",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:12:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "old fashioned",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:13:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "on the run",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:14:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "my secret",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:15:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "being sanguine",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:16:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "so confused",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:17:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "stressed out",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:18:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "daydreaming",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:19:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "light at the end",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:20:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "believe in scully",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:21:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "new begining",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:22:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "me gusta",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:23:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "street-smart",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:26:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "oh shit",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:27:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "mad men",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:29:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "brave heart",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:30:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "handsome",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:31:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "top10",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:32:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "expedition happiness",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:33:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "love me tender",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:34:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "breath in breath out",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:39:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "contentment",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:43:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "me me",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:44:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "Look up Assasine",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:45:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "commander pride",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:46:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "sweet home",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:47:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "peace!",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:48:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "satisfaction",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:49:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "the journey",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:50:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "strange 80s",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:51:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "dynamite discovery",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:53:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "netflix and chill",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:54:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "summer days",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:55:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "cambridge afloat",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:56:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "ready for action",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:57:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "diamond days",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:58:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "yeah yeah yeah",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:59:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "in love with the night",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:60:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "DONT YELL AT ME",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:61:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "lost my gameboy in the forest",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:62:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "explosive art",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:63:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "this is epic",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:64:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "erotic toy",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:65:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "heroic collar",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:66:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "nitty trippy",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:67:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "Against all odds",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:68:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "silent sloth",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:69:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "layback",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:70:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "streetmagic",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:71:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "the cathedral",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:72:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "messy",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:73:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "people be like",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:74:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "going through",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:75:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "TGIF",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:76:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "purple web",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:77:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "nuke the fridge",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:78:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "kinda quirky",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:79:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "rebellious",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:80:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "reckless mess",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:81:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "chill out",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:82:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "watermelon sugar",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:83:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "scary move",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:84:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "head over heels",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:89:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "searching",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:90:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "ocean bridge",
                "link": "https://opensea.io/assets/0x9951f5da4f4f21b7d39a80bc665edf31bd515009/91"
            },
            {
                "name": "sexual healing",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:92:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "beautiful and dangerous",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:93:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "a better tomorrow",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:94:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "hakuna matata",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:95:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "rick runway",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:96:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "i´m, here for you",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:97:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "gigil",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:98:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "spiritual animal",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:99:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "smash the guitar",
                "link": "https://opensea.io/assets/0x9951f5da4f4f21b7d39a80bc665edf31bd515009/100"
            },
            {
                "name": "skeleton in space",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:101:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "trippy",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:102:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "saudade",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:103:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "two pills",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:104:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "urgent request",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:105:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "forgiveness",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:106:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "welcome to mars",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:107:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "hikikomori",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:108:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "interested",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:109:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "high hope",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:110:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "daily desk victory",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:111:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "always dizzy",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:112:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "sleepy again",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:113:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            },
            {
                "name": "sweet boredom",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:114:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "excuse me what",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:115:0x92568635eb475bd6ff75e13e164a4d7a3f404a62"
            },
            {
                "name": "samurai",
                "link": "https://app.rarible.com/token/0x9951f5da4f4f21b7d39a80bc665edf31bd515009:116:0x92568635eb475bd6ff75e13e164a4d7a3f404a62?"
            }
        ]`;
}
